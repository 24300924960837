import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cn from "classnames";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  FaPhone,
  FaMapPin,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaHandPointer,
  FaUserAlt,
  FaSignOutAlt,
} from "react-icons/fa";
import { AiFillCaretRight } from "react-icons/ai";
import { useSignOut } from "react-auth-kit";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import { toast } from "react-toastify";

function Header({ type = false }) {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const { width } = useWindowSize();
  let navigate = useNavigate();
  const signOut = useSignOut();

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const languageMap = {
    tr: { label: "TR", dir: "ltr", active: true },
    en: { label: "EN", dir: "ltr", active: false },
  };

  const [infos, setInfos] = useState({});

  const getInfos = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "contact-infos/1")
      .then((res) => {
        if (res.data && res.data.status) {
          setInfos(res.data.data);
        }
      })
      .catch((err) => {
        setInfos({});
        console.log(err);
      });
  };

  useEffect(() => getInfos(), []);

  const logout = () => {
    toast.success(t("logoutSuccess"));

    setTimeout(() => {
      signOut();
      navigate("/");
    }, 1000);
  };

  return (
    <>
      <div className="bg-main ">
        <div className="  md:block  hidden md:container px-5 md:mx-auto  ">
          <div className="   h-11 flex justify-between items-center ">
            <div className=" ">
              <div className="flex ">
                {infos && infos.phone_1 && (
                  <div className="flex items-center md:mr-5 mr-2">
                    <span className="text-primary mr-1">{t("contact_card_person_1")}</span>
                    <FaPhone className="text-primary mr-1 w-5 h-5" />
                    <a href={"tel:" + infos.phone_1} className="text-white">
                      {infos.phone_1}
                    </a>
                  </div>
                )}
                <span className="text-primary md:mr-5 mr-2">|</span>

                {infos && infos.phone_2 && (
                  <div className="flex items-center md:mr-5 mr-2">
                    <span className="text-primary mr-1">{t("contact_card_person_2")}</span>
                    <FaPhone className="text-primary mr-1 w-5 h-5" />
                    <a href={"tel:" + infos.phone_2} className="text-white">
                      {infos.phone_2}
                    </a>
                  </div>
                )}

                {infos && infos.address && (
                  <div className="flex items-center">
                    <FaMapPin className="text-primary w-5 h-5 mr-1" />
                    <span className="text-white">{infos.address}</span>
                  </div>
                )}
              </div>
            </div>

            <div className=" flex items-center justify-center">
              <div className=" mr-5  ">
                <select
                  className="select appearance-none"
                  defaultValue={i18next.language}
                  onChange={(e) => i18next.changeLanguage(e.target.value)}
                >
                  {Object.keys(languageMap)?.map((item) => (
                    <option key={item} value={item}>
                      {languageMap[item].label}
                    </option>
                  ))}
                </select>
              </div>

              {infos && infos.facebook_url && (
                <a href={infos.facebook_url} target="_blank" className="line">
                  <FaFacebookF className=" text-white w-5 h-5 mr-5 " />
                </a>
              )}

              {infos && infos.twitter_url && (
                <a href={infos.twitter_url} target="_blank" className="line">
                  <FaTwitter className=" text-white w-5 h-5 mr-5 " />
                </a>
              )}

              {infos && infos.instagram_url && (
                <a href={infos.instagram_url} target="_blank" className="line">
                  <FaInstagram className=" text-white w-5 h-5 mr-5 " />
                </a>
              )}

              {infos && infos.linkledin_url && (
                <a href={infos.linkledin_url} target="_blank" className="line">
                  <FaLinkedinIn className=" text-white w-5 h-5 mr-5 " />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-lg">
        <nav className=" md:container mx-auto   px-5 navbar h-20 bg-white  justify-between flex items-center ">
          <div className="flex items-center  ">
            <div className="">
              <NavLink to="/" className="flex   items-center">
                <img
                  src="/assets/images/logo.svg"
                  alt="NDK Danışmanlık"
                  className="h-20 pr-5 "
                />
                <span className="font-semibold text-main md:text-lg text-sm" />
              </NavLink>
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-1">
            <NavLink
              className={cn([
                " py-4 px-2 font-bold",
                splitLocation[1] === "" ? "active" : "inActive",
              ])}
              to="/"
            >
              {t("global_header_home")}
            </NavLink>

            <NavLink
              className={cn([
                " py-4 px-2 font-bold",
                splitLocation[1] === "hakkimizda" ? "active" : "inActive",
              ])}
              to="/hakkimizda "
            >
              {t("global_header_about")}
            </NavLink>
            <NavLink
              to="/ekibimiz"
              className={cn([
                " py-4 px-2 font-bold",
                splitLocation[1] === "ekibimiz" ? "active" : "inActive",
              ])}
            >
              {t("global_header_psychologists")}
            </NavLink>
            <NavLink
              to="/hizmetlerimiz"
              className={cn([
                " py-4 px-2 font-bold",
                splitLocation[1] === "hizmetlerimiz" ? "active" : "inActive",
              ])}
            >
              {t("global_header_services")}
            </NavLink>
            <NavLink
              to="/blogs "
              className={cn([
                " py-4 px-2 font-bold",
                splitLocation[1] === "blogs" ? "active" : "inActive",
              ])}
            >
              {t("global_header_blog")}
            </NavLink>
            <NavLink
              to="/iletisim "
              className={cn([
                " py-4 px-2 font-bold",
                splitLocation[1] === "iletisim" ? "active" : "inActive",
              ])}
            >
              {t("global_header_contact")}
            </NavLink>
          </div>
          <div className="hidden md:flex items-center space-x-3 ">
            {type ? (
              <div className="flex">
                <NavLink to="/hesabim" className="flex mr-2">
                  <span
                    className="text-primary  bg-white flex items-center
                   px-4 py-2 border-primary border-2  rounded-l-lg font-bold  text-base   "
                  >
                    {t("global_account")}
                  </span>
                  <span className="bg-primary flex items-center text-white px-3 rounded-r-lg cursor-pointer  ">
                    <FaUserAlt />
                  </span>
                </NavLink>

                <button onClick={logout} className="flex min-h-full">
                  <span
                    className="text-main  bg-white flex items-center
                   px-4 py-2 border-main border-2  rounded-l-lg font-bold  text-base   "
                  >
                    {t("global_logout")}
                  </span>
                  <span className="bg-main flex items-center text-white px-3 rounded-r-lg cursor-pointer h-full ">
                    <FaSignOutAlt />
                  </span>
                </button>
              </div>
            ) : (
              <div className="appointment  flex">
                <NavLink
                  to="/giris-yap"
                  className={cn([
                    " py-2 px-4 font-bold",
                    splitLocation[1] === "giris-yap" ? "active" : "inActive",
                  ])}
                >
                  {t("login")}
                </NavLink>

                <NavLink to="/randevu-al" className="flex ">
                  <span className="text-white  bg-main flex items-center px-4 py-2 hover:text-primary rounded-l-lg font-bold  text-base   ">
                    {t("global_make_appointment")}
                  </span>
                  <span className="bg-primary flex items-center text-white px-3 rounded-r-lg cursor-pointer  ">
                    <FaHandPointer />
                  </span>
                </NavLink>
              </div>
            )}
          </div>

          <div className="md:hidden flex items-center">
            <div className="mr-5">
              <select
                className="select appearance-none"
                defaultValue={i18next.language}
                onChange={(e) => i18next.changeLanguage(e.target.value)}
              >
                {Object.keys(languageMap)?.map((item) => (
                  <option key={item} value={item}>
                    {languageMap[item].label}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="outline-none mobile-menu-button"
            >
              <svg
                className=" w-6 h-6 text-main hover:text-primary "
                x-show="!showMenu"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>

          <div
            className={cn([
              !showMenu && "hidden",
              "border-2 w-full left-0 top-20 absolute z-40 ",
            ])}
          >
            <ul className="">
              <li className="">
                <NavLink
                  to="/"
                  className="block text-sm px-3 py-4 bg-white text-white bg-primary font-semibold"
                >
                  {t("global_header_home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hakkimizda"
                  className="block text-sm px-3 py-4 bg-white hover:bg-primary transition duration-300"
                >
                  {t("global_header_about")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/ekibimiz"
                  className="block text-sm px-3 py-4 bg-white hover:bg-primary transition duration-300"
                >
                  {t("global_header_psychologists")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hizmetlerimiz"
                  className="block text-sm px-3 py-4 bg-white hover:bg-primary transition duration-300"
                >
                  {t("global_header_services")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blogs"
                  className="block text-sm px-3 py-4 bg-white hover:bg-primary transition duration-300"
                >
                  {t("global_header_blog")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/iletisim"
                  className="block text-sm px-3 py-4 bg-white hover:bg-primary transition duration-300"
                >
                  {t("global_header_contact")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/giris-yap"
                  className="block text-sm px-3 py-4 bg-white hover:bg-primary transition duration-300"
                >
                  {t("login")}
                </NavLink>
              </li>
              <li>
                <NavLink to="/randevu-al" className="flex bg-white pb-3 px-3 ">
                  <span className="text-white  bg-main flex items-center px-4 py-2 hover:text-primary rounded-l-lg font-bold  text-base   ">
                    {t("global_make_appointment")}
                  </span>
                  <span className="bg-primary flex items-center text-white px-3 rounded-r-lg cursor-pointer  ">
                    <FaHandPointer />
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        {type && (
          <div className="bg-main w-full py-3   px-2  ">
            <div className="md:container md:mx-auto     flex gap-2">
              <NavLink
                to="/hesabim"
                className={cn([
                  " flex items-center",
                  splitLocation[1] === "hesabim"
                    ? "text-primary"
                    : "text-white",
                ])}
              >
                <AiFillCaretRight className="mr-1" />
                {t("global_account")}
              </NavLink>
              <NavLink
                to="/sifre-islemleri"
                className={cn([
                  " flex items-center",
                  splitLocation[1] === "sifre-islemleri"
                    ? "text-primary"
                    : "text-white",
                ])}
              >
                <AiFillCaretRight className="mr-1" />
                {t("global_password_operations")}
              </NavLink>
              <NavLink
                to="/randevularim"
                className={cn([
                  " flex items-center",
                  splitLocation[1] === "randevularim"
                    ? "text-primary"
                    : "text-white",
                ])}
              >
                <AiFillCaretRight className="mr-1" />
                {t("global_appointments")}
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
