import React from "react";

export default function ContactCard({ icon, text, contactPerson1,number,contactPerson2, number2 }) {
  return (
    <div className="md:w-1/2 w-full">
      <div className="p-3 border-2 rounded-xl p-4 m-2">
        <div className="text-primary text-3xl mb-2">{icon}</div>
        <div className="mb-2">{text}</div>
          <div className="text-primary text-lg font-bold"><span className="mr-2">{contactPerson1}</span>{number}</div>
          {contactPerson2 && number2 && (
            <div className="text-primary text-lg font-bold"><span className="mr-2">{contactPerson2}</span>{number2}</div>
          )}
      </div>
    </div>
  );
}
